import axios from 'axios';
import { cloneDeep } from 'lodash';
import _get from 'lodash/get';
import { createTypes } from 'reduxsauce';
import axiosClient from 'utils/axiosClient';
import axiosClient2 from 'utils/axiosClient2';
import { ENDPOINTS } from './config';

export const EditVideoTypes = createTypes(`
  EDIT_VIDEO_RESET

  EDIT_VIDEO_FETCH_REQUEST
  EDIT_VIDEO_FETCH_SUCCESS
  EDIT_VIDEO_FETCH_FAILURE

  EDIT_VIDEO_HOT_UPDATE_REQUEST
  EDIT_VIDEO_HOT_UPDATE_SUCCESS
  EDIT_VIDEO_HOT_UPDATE_FAILURE

  EDIT_VIDEO_UPLOAD_MEDIA_REQUEST
  EDIT_VIDEO_UPLOAD_MEDIA_SUCCESS
  EDIT_VIDEO_UPLOAD_MEDIA_FAILURE

  EDIT_VIDEO_CREATE_SCENE_REQUEST
  EDIT_VIDEO_CREATE_SCENE_SUCCESS
  EDIT_VIDEO_CREATE_SCENE_FAILURE

  EDIT_VIDEO_DELETE_SCENE_REQUEST
  EDIT_VIDEO_DELETE_SCENE_SUCCESS
  EDIT_VIDEO_DELETE_SCENE_FAILURE

  EDIT_VIDEO_REORDER_SCENE_REQUEST
  EDIT_VIDEO_REORDER_SCENE_SUCCESS
  EDIT_VIDEO_REORDER_SCENE_FAILURE
  EDIT_VIDEO_SET_SELECTED_SCENE_ID

  EDIT_VIDEO_CHANGE_MEDIA_REQUEST
  EDIT_VIDEO_CHANGE_MEDIA_SUCCESS
  EDIT_VIDEO_CHANGE_MEDIA_FAILURE

  EDIT_VIDEO_SET_VIDEO_STATE

  EDIT_VIDEO_DUPLICATE_SCENE_REQUEST
  EDIT_VIDEO_DUPLICATE_SCENE_SUCCESS
  EDIT_VIDEO_DUPLICATE_SCENE_FAILURE

  EDIT_VIDEO_HOT_UPDATE_SCENE_REQUEST
  EDIT_VIDEO_HOT_UPDATE_SCENE_SUCCESS
  EDIT_VIDEO_HOT_UPDATE_SCENE_FAILURE

  EDIT_VIDEO_DELETE_MEDIA_REQUEST
  EDIT_VIDEO_DELETE_MEDIA_SUCCESS
  EDIT_VIDEO_DELETE_MEDIA_FAILURE

  EDIT_VIDEO_BUILD_REQUEST
  EDIT_VIDEO_BUILD_SUCCESS
  EDIT_VIDEO_BUILD_FAILURE

  EDIT_VIDEO_IMPORT_SLIDE_REQUEST
  EDIT_VIDEO_IMPORT_SLIDE_SUCCESS
  EDIT_VIDEO_IMPORT_SLIDE_FAILURE

  EDIT_VIDEO_RESIZE_SCENE_REQUEST
  EDIT_VIDEO_RESIZE_SCENE_SUCCESS
  EDIT_VIDEO_RESIZE_SCENE_FAILURE

  EDIT_VIDEO_CHANGE_LOGO_TEMPORARY

  EDIT_VIDEO_HOT_UPDATE_LOGO_REQUEST
  EDIT_VIDEO_HOT_UPDATE_LOGO_SUCCESS
  EDIT_VIDEO_HOT_UPDATE_LOGO_FAILURE

  EDIT_VIDEO_UPLOAD_LOGO_REQUEST
  EDIT_VIDEO_UPLOAD_LOGO_SUCCESS
  EDIT_VIDEO_UPLOAD_LOGO_FAILURE
  EDIT_VIDEO_UPLOAD_AUDIO_REQUEST
  EDIT_VIDEO_UPLOAD_AUDIO_FAILURE
  EDIT_VIDEO_UPLOAD_AUDIO_SUCCESS

  EDIT_VIDEO_DELETE_AUDIO_REQUEST
  EDIT_VIDEO_DELETE_AUDIO_FAILURE
  EDIT_VIDEO_DELETE_AUDIO_SUCCESS

  EDIT_VIDEO_ADD_SOUND_TO_VIDEO_REQUEST
  EDIT_VIDEO_ADD_SOUND_TO_VIDEO_FAILURE
  EDIT_VIDEO_ADD_SOUND_TO_VIDEO_SUCCESS



  EDIT_VIDEO_ADD_NEW_FOLDER
  EDIT_VIDEO_SET_SELECTED_FOLDER_ID

  EDIT_VIDEO_ADD_NEW_FOLDER
  EDIT_VIDEO_DELETE_FOLDER
  EDIT_VIDEO_SET_SELECTED_FOLDER_ID
  EDIT_VIDEO_CHANGE_VOLUME_VIDEO_AND_SCENE_REQUEST
  EDIT_VIDEO_CHANGE_VOLUME_VIDEO_AND_SCENE_SUCCESS
  EDIT_VIDEO_CHANGE_VOLUME_VIDEO_AND_SCENE_FAILURE

  EDIT_VIDEO_DELETE_MEDIA_IN_FOLDER_SUCCESS
  EDIT_VIDEO_DELETE_MEDIA_IN_FOLDER_FAILURE
  EDIT_VIDEO_DELETE_MEDIA_IN_FOLDER_REQUEST
  EDIT_VIDEO_UPDATE_URL_AUDIO
  EDIT_VIDEO_UPLOAD_SPEECH_REQUEST
  EDIT_VIDEO_UPLOAD_SPEECH_SUCCESS
  EDIT_VIDEO_UPLOAD_SPEECH_FAILURE
  EDIT_VIDEO_DELETE_MEDIA_IN_FOLDER_FAILURE
  EDIT_VIDEO_DELETE_MEDIA_IN_FOLDER_REQUEST
  EDIT_VIDEO_UPDATE_URL_AUDIO
  EDIT_VIDEO_UPDATE_URL_AUDIO_HAS_EFFECT
  
  EDIT_VIDEO_APPLY_SCENE_TEMPLATE_REQUEST
  EDIT_VIDEO_APPLY_SCENE_TEMPLATE_SUCCESS
  EDIT_VIDEO_APPLY_SCENE_TEMPLATE_FAILURE

  EDIT_VIDEO_UPDATE_SCENE_FE_ONLY
  EDIT_VIDEO_SET_LOADING_SCENE

  EDIT_VIDEO_APPLY_SCENE_TEMPLATE_FOR_ALL_REQUEST
  EDIT_VIDEO_APPLY_SCENE_TEMPLATE_FOR_ALL_SUCCESS
  EDIT_VIDEO_APPLY_SCENE_TEMPLATE_FOR_ALL_FAILURE
  EDIT_VIDEO_UPDATE_MEDIA_FOLDER_REQUEST
  EDIT_VIDEO_UPDATE_MEDIA_FOLDER_SUCCESS
  EDIT_VIDEO_UPDATE_MEDIA_FOLDER_FAILURE

  EDIT_VIDEO_DELETE_LOGO

  EDIT_VIDEO_UPDATE_FE_ONLY

  EDIT_VIDEO_DELETE_TEMPLATE_SUCCESS
  EDIT_VIDEO_DELETE_TEMPLATE_FAILURE
  EDIT_VIDEO_DELETE_TEMPLATE_REQUEST

  EDIT_VIDEO_SET_TEMPLATE

  EDIT_VIDEO_UPDATE_VOICE_AND_SPEED

  EDIT_VIDEO_SET_TIME_UPDATE_SCENE
  EDIT_VIDEO_SET_SCENE_CHECK

  CREATE_NEW_VIDEO_FROM_SLICE_REQUEST
  CREATE_NEW_VIDEO_FROM_SLIDE_SUCCESS
`);

export const resetRequest = () => ({ type: EditVideoTypes.EDIT_VIDEO_RESET });

export const fetchSuccess = (video, tags) => ({ type: EditVideoTypes.EDIT_VIDEO_FETCH_SUCCESS, video, tags });

export const fetchFailure = (error) => ({ type: EditVideoTypes.EDIT_VIDEO_FETCH_FAILURE, error });

export const fetchRequest = (id) => async (dispatch) => {
  try {
    dispatch({ type: EditVideoTypes.EDIT_VIDEO_FETCH_REQUEST });
    const rs = await axiosClient.get(ENDPOINTS.video(id));
    const rs2 = await axiosClient.get('tags');
    dispatch(fetchSuccess(rs.video, rs2.tags));
  } catch (e) {
    dispatch(fetchFailure(e));
  }
};

export const hotUpdateSuccess = (video, isChangingFormat) => ({
  type: EditVideoTypes.EDIT_VIDEO_HOT_UPDATE_SUCCESS,
  video,
  isChangingFormat
});

export const hotUpdateFailure = (error, video) => ({
  type: EditVideoTypes.EDIT_VIDEO_HOT_UPDATE_FAILURE,
  error,
  video
});

export const hotUpdateRequest =
  (id, data, isChangingFormat = false) =>
  async (dispatch, getState) => {
    const previousVideo = _get(getState(), 'editVideo.video', null);
    try {
      dispatch({ type: EditVideoTypes.EDIT_VIDEO_HOT_UPDATE_REQUEST, data, changeFormatLoading: isChangingFormat });
      const rs = await axiosClient.patch(ENDPOINTS.video(id), data);
      dispatch(hotUpdateSuccess(rs.video, isChangingFormat));
    } catch (e) {
      dispatch(hotUpdateFailure(e, previousVideo));
    }
  };

export const uploadMediaSuccess = (data, isInFolder) => ({
  type: EditVideoTypes.EDIT_VIDEO_UPLOAD_MEDIA_SUCCESS,
  data,
  isInFolder
});

export const uploadMediaFailure = (error) => ({ type: EditVideoTypes.EDIT_VIDEO_UPLOAD_MEDIA_FAILURE, error });

export const uploadMediaRequest = (id, files, folderID) => (dispatch) => {
  const formData = new FormData();
  let listFile = [];
  for (let i = 0; i < files.length; i++) {
    formData.append(`files[]`, files[i]);
    listFile = [files[i], ...listFile];
  }
  if (folderID) {
    formData.append('folder_id', folderID);
  }
  dispatch({ type: EditVideoTypes.EDIT_VIDEO_UPLOAD_MEDIA_REQUEST, files: listFile, folderID });

  return axios.post(`/media`, formData, {
    baseURL: process.env.REACT_APP_BASE_URL,
    headers: {
      'Content-type': 'multipart/form-data',
      Authorization: `Bearer ${localStorage.getItem('access_token')}`
    }
  });
};

export const createSceneSuccess = (scene) => ({ type: EditVideoTypes.EDIT_VIDEO_CREATE_SCENE_SUCCESS, scene });

export const createSceneFailure = (error) => ({ type: EditVideoTypes.EDIT_VIDEO_CREATE_SCENE_FAILURE, error });

export const createSceneRequest = (id, order) => async (dispatch, getState) => {
  const listScene = _get(getState(), 'editVideo.video.scenes', []);
  const newListScene = listScene.slice(0);
  newListScene.push({ _id: '~new', order, loading: true });
  try {
    dispatch({ type: EditVideoTypes.EDIT_VIDEO_CREATE_SCENE_REQUEST, scenes: newListScene });
    const rs = await axiosClient.post(ENDPOINTS.scenes(id), { order, duration: 3 });
    dispatch(setSelectedSceneID(rs.scene._id));
    dispatch(createSceneSuccess(rs.scene));
  } catch (e) {
    dispatch(createSceneFailure(e, listScene));
  }
};

export const deleteSceneSuccess = (scenes) => ({ type: EditVideoTypes.EDIT_VIDEO_DELETE_SCENE_SUCCESS, scenes });

export const deleteSceneFailure = (error, scenes) => ({
  type: EditVideoTypes.EDIT_VIDEO_DELETE_SCENE_FAILURE,
  error,
  scenes
});

export const deleteSceneRequest = (videoID, sceneID) => async (dispatch, getState) => {
  const listScene = _get(getState(), 'editVideo.video.scenes', []);
  // const newListScene = listScene.filter((sc) => sc._id !== sceneID).map((sc, order) => ({ ...sc, order }));
  try {
    dispatch({ type: EditVideoTypes.EDIT_VIDEO_DELETE_SCENE_REQUEST, sceneID });
    const rs = await axiosClient.delete(ENDPOINTS.scene(videoID, sceneID));

    dispatch(deleteSceneSuccess(rs.scenes));
  } catch (e) {
    dispatch(deleteSceneFailure(e, listScene));
  }
};

export const reorderSceneSuccess = (scenes) => ({ type: EditVideoTypes.EDIT_VIDEO_REORDER_SCENE_SUCCESS, scenes });

export const reorderSceneFailure = (error, scenes) => ({
  type: EditVideoTypes.EDIT_VIDEO_REORDER_SCENE_FAILURE,
  error,
  scenes
});

export const reorderSceneRequest = (videoID, result) => async (dispatch, getState) => {
  const listScene = _get(getState(), 'editVideo.video.scenes', []);
  const reorderListScene = listScene.slice(0);
  const [removed] = reorderListScene.splice(result.source.index, 1);
  reorderListScene.splice(result.destination.index, 0, removed);
  const newList = reorderListScene.map((sc, order) => ({ ...sc, order }));

  try {
    dispatch({ type: EditVideoTypes.EDIT_VIDEO_REORDER_SCENE_REQUEST, scenes: newList });
    const rs = await axiosClient.post(ENDPOINTS.arrangeScene(videoID), {
      sort_list: newList.reduce((prevObj, scene) => ({ ...prevObj, [scene._id]: scene.order }), {})
    });
    dispatch(reorderSceneSuccess(rs.scenes));
  } catch (e) {
    dispatch(reorderSceneFailure(e, listScene));
  }
};

export const setSelectedSceneID = (id) => ({ type: EditVideoTypes.EDIT_VIDEO_SET_SELECTED_SCENE_ID, id });
export const setSelectedFolderID = (id) => ({ type: EditVideoTypes.EDIT_VIDEO_SET_SELECTED_FOLDER_ID, id });

export const changeMediaSuccess = (scene) => ({ type: EditVideoTypes.EDIT_VIDEO_CHANGE_MEDIA_SUCCESS, scene });

export const changeMediaFailure = (error) => ({ type: EditVideoTypes.EDIT_VIDEO_CHANGE_MEDIA_FAILURE, error });

export const changeMediaRequest =
  ({ videoID, mediaID }) =>
  async (dispatch, getState) => {
    const selectingSceneID = _get(getState(), 'editVideo.selectingStatus.sceneID', null);
    if (!selectingSceneID) return;
    try {
      dispatch({ type: EditVideoTypes.EDIT_VIDEO_CHANGE_MEDIA_REQUEST });
      const rs = await axiosClient.patch(ENDPOINTS.scene(videoID, selectingSceneID), { media_id: mediaID });
      dispatch(changeMediaSuccess(rs.scene));
    } catch (e) {
      dispatch(changeMediaFailure(e));
    }
  };

export const setVideoState = (state) => ({ type: EditVideoTypes.EDIT_VIDEO_SET_VIDEO_STATE, state });

export const duplicateSceneSuccess = (scene) => ({
  type: EditVideoTypes.EDIT_VIDEO_DUPLICATE_SCENE_SUCCESS,
  scene
});

export const duplicateSceneFailure = (error, scenes) => ({
  type: EditVideoTypes.EDIT_VIDEO_DUPLICATE_SCENE_FAILURE,
  error,
  scenes
});

export const duplicateSceneRequest =
  ({ videoID, scene, index }) =>
  async (dispatch, getState) => {
    const listScene = _get(getState(), 'editVideo.video.scenes', []);
    const newListScene = listScene.slice(0);
    newListScene.splice(index + 1, 0, { ...scene, _id: `~duplicate`, loading: true });
    try {
      dispatch({ type: EditVideoTypes.EDIT_VIDEO_DUPLICATE_SCENE_REQUEST, scenes: newListScene });
      const rs = await axiosClient.post(ENDPOINTS.duplicateScene(videoID, scene._id));

      dispatch(duplicateSceneSuccess(rs.scene));
    } catch (e) {
      dispatch(duplicateSceneFailure(e, listScene));
    }
  };

export const hotUpdateSceneSuccess = (scene) => ({ type: EditVideoTypes.EDIT_VIDEO_HOT_UPDATE_SCENE_SUCCESS, scene });

export const hotUpdateSceneFailure = (error, scene) => ({
  type: EditVideoTypes.EDIT_VIDEO_HOT_UPDATE_SCENE_FAILURE,
  error,
  scene
});

export const updateSceneRequest =
  ({ videoID, sceneID, data }) =>
  async (dispatch, getState) => {
    const newScene = { ...data };
    try {
      dispatch({ type: EditVideoTypes.EDIT_VIDEO_HOT_UPDATE_SCENE_REQUEST, scene: newScene });
      await axiosClient.patch(ENDPOINTS.scene(videoID, sceneID), data);
    } catch (e) {
      console.log(e);
    }
  };

export const hotUpdateSceneRequest =
  ({ videoID, sceneID, data, isDeleteMedia = false }) =>
  async (dispatch, getState) => {
    const prevScene = _get(getState(), 'editVideo.video.scenes', []).find((sc) => sc._id === sceneID);
    const newScene = { ...prevScene, ...data };
    const params = cloneDeep(data);
    if (params.speech) {
      delete params.speech?.full_url;
      delete params.speech?.original_url;
      delete params.speech?.url;
      delete params.speech?.loadingScene;
    }

    try {
      dispatch({ type: EditVideoTypes.EDIT_VIDEO_HOT_UPDATE_SCENE_REQUEST, scene: newScene });
      const res = await axiosClient.patch(
        ENDPOINTS.scene(videoID, sceneID),
        isDeleteMedia ? { media_id: null } : params
      );
      dispatch(hotUpdateSceneSuccess(newScene));
      return res;
    } catch (e) {
      console.log(e);
      dispatch(hotUpdateSceneFailure(e, prevScene));
    }
  };

export const updateSceneSpeechRequest =
  ({ videoID, sceneID, data }) =>
  async (dispatch) => {
    try {
      await axiosClient.patch(ENDPOINTS.updateSpeech(videoID, sceneID), data);
    } catch (e) {
      console.log(e);
    }
  };

export const deleteMediaSuccess = (media) => ({ type: EditVideoTypes.EDIT_VIDEO_DELETE_MEDIA_SUCCESS, media });

export const deleteMediaFailure = (error, media) => ({
  type: EditVideoTypes.EDIT_VIDEO_DELETE_MEDIA_FAILURE,
  error,
  media
});

export const deleteMediaRequest =
  ({ mediaID, folderID }) =>
  async (dispatch, getState) => {
    const listMedia = _get(getState(), 'editVideo.video.media', []);
    const listFolder = _get(getState(), 'editVideo.video.folders', []);
    const newListMedia = listMedia.filter((media) => media._id !== mediaID);
    const newListFolder = listFolder.map((folder) =>
      folder._id === folderID ? { ...folder, media: folder.media.filter((media) => media._id !== mediaID) } : folder
    );
    try {
      dispatch({ type: EditVideoTypes.EDIT_VIDEO_DELETE_MEDIA_REQUEST, media: newListMedia, folders: newListFolder });
      await axiosClient.delete(ENDPOINTS.media(mediaID));
      dispatch(deleteMediaSuccess());
    } catch (e) {
      dispatch(deleteMediaFailure(e, listMedia, listFolder));
    }
  };
export const deleteMediaInFolderSuccess = (media) => ({
  type: EditVideoTypes.EDIT_VIDEO_DELETE_MEDIA_IN_FOLDER_SUCCESS,
  media
});

export const deleteMediaInFolderFailure = (error, media) => ({
  type: EditVideoTypes.EDIT_VIDEO_DELETE_MEDIA_IN_FOLDER_FAILURE,
  error,
  media
});

export const deleteMediaInFolderRequest = (mediaID) => async (dispatch, getState) => {
  const listMediaInFolder = _get(getState(), 'editVideo.video.folders.media', []);
  const newListMediaInFolder = listMediaInFolder.filter((media) => media._id !== mediaID);
  try {
    dispatch({ type: EditVideoTypes.EDIT_VIDEO_DELETE_MEDIA_IN_FOLDER_REQUEST, media: newListMediaInFolder });
    await axiosClient.delete(ENDPOINTS.media(mediaID));
    dispatch(deleteMediaInFolderSuccess());
  } catch (e) {
    dispatch(deleteMediaInFolderFailure(e, listMediaInFolder));
  }
};

export const buildSuccess = (url) => ({ type: EditVideoTypes.EDIT_VIDEO_BUILD_SUCCESS, url });

export const buildFailure = (error) => ({
  type: EditVideoTypes.EDIT_VIDEO_BUILD_FAILURE,
  error
});

export const buildRequest =
  ({ videoID, data }) =>
  (dispatch) => {
    dispatch({ type: EditVideoTypes.EDIT_VIDEO_BUILD_REQUEST });
    return axiosClient.post(ENDPOINTS.buildVideo(videoID), data);
  };

export const importSlideSuccess = (scenes) => ({
  type: EditVideoTypes.EDIT_VIDEO_IMPORT_SLIDE_SUCCESS,
  scenes
});

export const importSlideFailure = (error, listScene) => ({
  type: EditVideoTypes.EDIT_VIDEO_IMPORT_SLIDE_FAILURE,
  error,
  listScene
});

export const importSlideRequest = (id, file, config) => async (dispatch, getState) => {
  const formData = new FormData();
  formData.append('file', file);
  const listScene = _get(getState(), 'editVideo.video.scenes', []);
  try {
    dispatch({ type: EditVideoTypes.EDIT_VIDEO_IMPORT_SLIDE_REQUEST });
    const response = await axiosClient.post(ENDPOINTS.importSlide(id), formData, config);
    const newListScene = [...response.scenes];
    dispatch(importSlideSuccess(newListScene));
    return response;
  } catch (e) {
    dispatch(importSlideFailure(e, listScene));
    return e;
  }
};

export const resizeSceneSuccess = (scene) => ({ type: EditVideoTypes.EDIT_VIDEO_RESIZE_SCENE_SUCCESS, scene });

export const resizeSceneFailure = (error, scene) => ({
  type: EditVideoTypes.EDIT_VIDEO_RESIZE_SCENE_FAILURE,
  error,
  scene
});

export const resizeSceneRequest =
  ({ videoID, sceneID, data }) =>
  async (dispatch) => {
    try {
      dispatch({ type: EditVideoTypes.EDIT_VIDEO_RESIZE_SCENE_REQUEST });
      const rs = await axiosClient.patch(ENDPOINTS.resize(videoID, sceneID), { ...data });
      dispatch(resizeSceneSuccess(rs.scene));
    } catch (e) {
      dispatch(resizeSceneFailure(e));
    }
  };

export const changeLogoTemporary = (props) => ({ type: EditVideoTypes.EDIT_VIDEO_CHANGE_LOGO_TEMPORARY, props });
export const hotUpdateLogoSuccess = () => ({
  type: EditVideoTypes.EDIT_VIDEO_HOT_UPDATE_LOGO_SUCCESS
});

export const hotUpdateLogoFailure = (error, logo) => ({
  type: EditVideoTypes.EDIT_VIDEO_HOT_UPDATE_LOGO_FAILURE,
  error,
  logo
});

export const hotUpdateLogoRequest = (id, logo) => async (dispatch, getState) => {
  const prev = _get(getState(), 'editVideo.video.logo', null);
  try {
    dispatch({
      type: EditVideoTypes.EDIT_VIDEO_HOT_UPDATE_LOGO_REQUEST,
      logo
    });
    await axiosClient.patch(ENDPOINTS.logo(id), { ...logo });
    dispatch(hotUpdateLogoSuccess());
  } catch (e) {
    dispatch(hotUpdateLogoFailure(e, prev));
  }
};

export const uploadLogoSuccess = (logo) => ({ type: EditVideoTypes.EDIT_VIDEO_UPLOAD_LOGO_SUCCESS, logo });

export const uploadLogoFailure = (error) => ({ type: EditVideoTypes.EDIT_VIDEO_UPLOAD_LOGO_FAILURE, error });

export const uploadLogoRequest = (id, files) => (dispatch) => {
  const formData = new FormData();
  formData.append(`file`, files[0]);
  dispatch({ type: EditVideoTypes.EDIT_VIDEO_UPLOAD_LOGO_REQUEST });

  return axios.post(`/videos/${id}/logo`, formData, {
    baseURL: process.env.REACT_APP_BASE_URL,
    headers: {
      'Content-type': 'multipart/form-data',
      Authorization: `Bearer ${localStorage.getItem('access_token')}`
    }
  });
};

export const uploadAudioSuccess = (sound) => ({ type: EditVideoTypes.EDIT_VIDEO_UPLOAD_AUDIO_SUCCESS, sound });

export const uploadAudioFailure = (error) => ({ type: EditVideoTypes.EDIT_VIDEO_UPLOAD_AUDIO_FAILURE, error });

export const uploadAudioRequest = (id, files) => (dispatch) => {
  const formData = new FormData();
  let listFile = [];
  for (let i = 0; i < files.length; i++) {
    formData.append(`files[]`, files[i]);
    listFile = [...listFile, files[i]];
  }
  dispatch({ type: EditVideoTypes.EDIT_VIDEO_UPLOAD_AUDIO_REQUEST, files: listFile });

  return axios.post(`/media`, formData, {
    baseURL: process.env.REACT_APP_BASE_URL,
    headers: {
      'Content-type': 'multipart/form-data',
      Authorization: `Bearer ${localStorage.getItem('access_token')}`
    }
  });
};
export const deleteAudioSuccess = (sounds) => ({ type: EditVideoTypes.EDIT_VIDEO_DELETE_AUDIO_SUCCESS, sounds });

export const deleteAudioFailure = (error, sounds) => ({
  type: EditVideoTypes.EDIT_VIDEO_DELETE_AUDIO_FAILURE,
  error,
  sounds
});

export const deleteAudioRequest =
  ({ soundID }) =>
  async (dispatch, getState) => {
    const listSound = _get(getState(), 'editVideo.video.sounds', []);
    const newListSound = listSound.filter((sound) => sound._id !== soundID);
    try {
      dispatch({ type: EditVideoTypes.EDIT_VIDEO_DELETE_AUDIO_REQUEST, sounds: newListSound });
      await axiosClient.delete(ENDPOINTS.deleteSound(soundID));
      dispatch(deleteAudioSuccess(newListSound));
    } catch (e) {
      dispatch(deleteAudioFailure(e, listSound));
    }
  };

export const addSoundToVideoRequest = (videoID, soundID) => async (dispatch, getState) => {
  const backgroundSoundList = _get(getState(), 'editVideo.video.background_sound', {});
  dispatch({ type: EditVideoTypes.EDIT_VIDEO_ADD_SOUND_TO_VIDEO_REQUEST, soundID });
  try {
    const rs = await axiosClient.patch(ENDPOINTS.addSoundToVideo(videoID), { sound_id: soundID });

    dispatch({ type: EditVideoTypes.EDIT_VIDEO_ADD_SOUND_TO_VIDEO_SUCCESS, backgroundSound: rs.background_sound });
  } catch (e) {
    console.log(e);
    dispatch({ type: EditVideoTypes.EDIT_VIDEO_ADD_SOUND_TO_VIDEO_FAILURE, error: e, backgroundSoundList });
  }
};

export const addSoundToVideoSuccess = (backgroundSound) => ({
  type: EditVideoTypes.EDIT_VIDEO_ADD_SOUND_TO_VIDEO_SUCCESS,
  backgroundSound
});
export const addSoundToVideoFailure = (error, backgroundSoundList) => ({
  type: EditVideoTypes.EDIT_VIDEO_ADD_SOUND_TO_VIDEO_FAILURE,
  error,
  backgroundSoundList
});

export const addFolder = (folder) => ({ type: EditVideoTypes.EDIT_VIDEO_ADD_NEW_FOLDER, folder });
export const changeVolumeVideoAndSceneRequest =
  (videoID, sceneVolume, videoVolume, voiceSpeech, speedSpeech) => async (dispatch, getState) => {
    const _sceneVolume = _get(getState(), 'editVideo.video.scene_volume', 0);
    const _videoVolume = _get(getState(), 'editVideo.video.background_volume', 0);
    dispatch({ type: EditVideoTypes.EDIT_VIDEO_CHANGE_VOLUME_VIDEO_AND_SCENE_REQUEST, sceneVolume, videoVolume });
    try {
      await Promise.all([
        axiosClient.patch(`/videos/${videoID}`, {
          voice: voiceSpeech,
          speed: speedSpeech
        }),
        axiosClient.patch(ENDPOINTS.changeVolumeVideoAndScene(videoID), {
          scene_volume: sceneVolume,
          background_volume: videoVolume
        })
      ]);
      dispatch({ type: EditVideoTypes.EDIT_VIDEO_CHANGE_VOLUME_VIDEO_AND_SCENE_SUCCESS, voiceSpeech, speedSpeech });
    } catch (e) {
      dispatch({
        type: EditVideoTypes.EDIT_VIDEO_CHANGE_VOLUME_VIDEO_AND_SCENE_FAILURE,
        error: e,
        videoVolume: _videoVolume,
        sceneVolume: _sceneVolume
      });
    }
  };

export const changeVolumeVideoAndSceneSuccess = (voiceSpeech, speedSpeech) => ({
  type: EditVideoTypes.EDIT_VIDEO_CHANGE_VOLUME_VIDEO_AND_SCENE_SUCCESS,
  voiceSpeech,
  speedSpeech
});
export const changeVolumeVideoAndSceneFailure = (error) => ({
  type: EditVideoTypes.EDIT_VIDEO_EDIT_VIDEO_CHANGE_VOLUME_VIDEO_AND_SCENE_FAILURE,
  error
});

export const updateAudioInfo = (id, url, voice, speed, duration) => ({
  type: EditVideoTypes.EDIT_VIDEO_UPDATE_URL_AUDIO,
  id,
  url,
  voice,
  speed,
  duration
});

export const updateAudioInfoHasEffect = (id, url, voice, speed, duration, idx) => ({
  type: EditVideoTypes.EDIT_VIDEO_UPDATE_URL_AUDIO_HAS_EFFECT,
  id,
  url,
  voice,
  speed,
  duration,
  idx
});

export const deleteFolder = (id) => ({
  type: EditVideoTypes.EDIT_VIDEO_DELETE_FOLDER,
  id
});

export const uploadSpeechRequest =
  ({ videoID, sceneID, files, idx }) =>
  async (dispatch) => {
    const formData = new FormData();
    formData.append(`file`, files[0]);
    if (idx || idx === 0) {
      formData.append(`index`, idx);
    }
    dispatch({ type: EditVideoTypes.EDIT_VIDEO_UPLOAD_SPEECH_REQUEST });
    return axiosClient.post(ENDPOINTS.uploadSpeech(videoID, sceneID), formData, {
      baseURL: process.env.REACT_APP_BASE_URL,
      headers: {
        'Content-type': 'multipart/form-data',
        Authorization: `Bearer ${localStorage.getItem('access_token')}`
      }
    });
  };

export const uploadSpeechSuccess = (id, speechUrl) => ({
  type: EditVideoTypes.EDIT_VIDEO_UPLOAD_SPEECH_SUCCESS,
  id,
  speechUrl
});

export const uploadSpeechFailure = (error) => ({ type: EditVideoTypes.EDIT_VIDEO_UPLOAD_SPEECH_FAILURE, error });

export const setUrlUploadSpeech = (url) => ({ type: EditVideoTypes.EDIT_VIDEO_SET_URL_UPLOAD_SPEECH, url });

export const applySceneTemplateSuccess = (scene) => ({
  type: EditVideoTypes.EDIT_VIDEO_APPLY_SCENE_TEMPLATE_SUCCESS,
  scene
});

export const applySceneTemplateFailure = (error) => ({
  type: EditVideoTypes.EDIT_VIDEO_APPLY_SCENE_TEMPLATE_FAILURE,
  error
});

export const applySceneTemplateRequest = (sceneTemplateID) => async (dispatch, getState) => {
  const videoID = _get(getState(), 'editVideo.video._id');
  const selectingSceneID = _get(getState(), 'editVideo.selectingStatus.sceneID', null);
  if (!selectingSceneID) return;
  try {
    dispatch({ type: EditVideoTypes.EDIT_VIDEO_APPLY_SCENE_TEMPLATE_REQUEST });
    const rs = await axiosClient.post(ENDPOINTS.applySceneTemplate(videoID, selectingSceneID, sceneTemplateID));
    dispatch(applySceneTemplateSuccess(rs.scene));
  } catch (e) {
    dispatch(applySceneTemplateFailure(e));
  }
};

export const updateSceneFEOnly = (sceneID, data) => ({
  type: EditVideoTypes.EDIT_VIDEO_UPDATE_SCENE_FE_ONLY,
  sceneID,
  data
});
export const setLoadingScene = (id, scene) => ({ type: EditVideoTypes.EDIT_VIDEO_SET_LOADING_SCENE, id, scene });

export const applySceneTemplateForAllRequest = (videoID, templateID) => async (dispatch, getState) => {
  const listScenes = _get(getState(), 'editVideo.video.scenes', []);
  dispatch({ type: EditVideoTypes.EDIT_VIDEO_APPLY_SCENE_TEMPLATE_FOR_ALL_REQUEST });
  try {
    const rs = await axiosClient.post(ENDPOINTS.applySceneTemplateForAll(videoID, templateID));
    dispatch({ type: EditVideoTypes.EDIT_VIDEO_APPLY_SCENE_TEMPLATE_FOR_ALL_SUCCESS, scenes: rs.scenes });
  } catch (e) {
    dispatch({ type: EditVideoTypes.EDIT_VIDEO_APPLY_SCENE_TEMPLATE_FOR_ALL_FAILURE, error: e, listScenes });
  }
};

export const applySceneTemplateForAllSuccess = () => ({
  type: EditVideoTypes.EDIT_VIDEO_APPLY_SCENE_TEMPLATE_FOR_ALL_SUCCESS
});

export const applySceneTemplateForAllFailure = (error) => ({
  type: EditVideoTypes.EDIT_VIDEO_APPLY_SCENE_TEMPLATE_FOR_ALL_SUCCESS,
  error
});
export const updateMediaFolderSuccess = (folder) => ({
  type: EditVideoTypes.EDIT_VIDEO_UPDATE_MEDIA_FOLDER_SUCCESS,
  folder
});

export const updateMediaFolderFailure = (error, id) => ({
  type: EditVideoTypes.EDIT_VIDEO_UPDATE_MEDIA_FOLDER_FAILURE,
  error,
  id
});

export const updateMediaFolderRequest = (id, data) => async (dispatch) => {
  try {
    dispatch({ type: EditVideoTypes.EDIT_VIDEO_UPDATE_MEDIA_FOLDER_REQUEST, id, data });
    const rs = await axiosClient.patch(`/folders/${id}`, data);
    dispatch(updateMediaFolderSuccess(rs.folder));
  } catch (e) {
    console.log(e);
    dispatch(updateMediaFolderFailure(e, id));
  }
};

export const deleteLogo = () => ({ type: EditVideoTypes.EDIT_VIDEO_DELETE_LOGO });

export const deleteTemplateRequest = (id) => async (dispatch, getState) => {
  const listTemplate = _get(getState(), 'editVideo.myTemplates', []);
  const newListTemplate = listTemplate.filter((template) => template._id !== id);
  try {
    dispatch({ type: EditVideoTypes.EDIT_VIDEO_DELETE_TEMPLATE_REQUEST });
    await axiosClient.delete(ENDPOINTS.deleteTemplate(id));
    dispatch(deleteTemplateSuccess(newListTemplate));
  } catch (e) {
    console.log(e);
    dispatch(deleteTemplateFailure(e, listTemplate));
  }
};

export const deleteTemplateSuccess = (templates) => ({
  type: EditVideoTypes.EDIT_VIDEO_DELETE_TEMPLATE_SUCCESS,
  templates
});

export const deleteTemplateFailure = (error, listTemplate) => ({
  type: EditVideoTypes.EDIT_VIDEO_DELETE_TEMPLATE_FAILURE,
  error,
  listTemplate
});

// my template
export const setTemplate = (templates) => ({ type: EditVideoTypes.EDIT_VIDEO_SET_TEMPLATE, templates });

export const updateVoiceAndSpeed = (voice, speed) => ({
  type: EditVideoTypes.EDIT_VIDEO_UPDATE_VOICE_AND_SPEED,
  voice,
  speed
});

export const setTimeUpdateScene = () => ({ type: EditVideoTypes.EDIT_VIDEO_SET_TIME_UPDATE_SCENE });

export const setSceneCheck = (sceneCheck) => ({ type: EditVideoTypes.EDIT_VIDEO_SET_SCENE_CHECK, sceneCheck });

export const sliceScorm = (data) => async (dispatch) => {
  try {
    return axiosClient2.post(`/library/scorm`, data);
  } catch (e) {
    console.log(e);
  }
};

export const createNewVideoFromSlideRequest = (file, videoID) => async (dispatch) => {
  const formData = new FormData();
  formData.append('file', file);

  try {
    const response = await axiosClient.post(ENDPOINTS.createVideo(videoID), formData);
    const resVideo = await axiosClient.get(ENDPOINTS.video(response.video._id));
    dispatch({
      type: EditVideoTypes.CREATE_NEW_VIDEO_FROM_SLIDE_SUCCESS,
      video: resVideo.video
    });
    return resVideo;
  } catch (e) {
    console.log(e);
  }
};
