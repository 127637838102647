import { createTypes } from 'reduxsauce';
import axiosClient from 'utils/axiosClient';
import _get from 'lodash/get';
import { ENDPOINTS } from './config';

export const TemplatesListTypes = createTypes(`
GET_MY_TEMPLATES

  GET_TEMPLATE_SEARCH_REQUEST
GET_TEMPLATE_SEARCH_SUCCESS
GET_TEMPLATE_SEARCH_FAILURE

  GET_TEMPLATE_LIBRARY

  DELETE_TEMPLATE_REQUEST
DELETE_TEMPLATE_SUCCESS
DELETE_TEMPLATE_FAILURE
`);

export const getMyTemplates = (templates) => ({
  type: TemplatesListTypes.GET_MY_TEMPLATES,
  templates
});

export const searchTemplateSuccess = (templates) => ({
  type: TemplatesListTypes.GET_TEMPLATE_SEARCH_SUCCESS,
  templates
});
export const searchTemplateFailure = (error) => ({ type: TemplatesListTypes.GET_TEMPLATE_SEARCH_FAILURE, error });
export const searchTemplateRequest = (searchTerm) => async (dispatch) => {
  try {
    dispatch({ type: TemplatesListTypes.GET_TEMPLATE_SEARCH_REQUEST, searchTerm });
    const response = await axiosClient.get(ENDPOINTS.searchTemplates(), {
      params: searchTerm
    });
    dispatch(searchTemplateSuccess(response.templates.data));
  } catch (e) {
    dispatch(searchTemplateFailure(e));
  }
};

export const getTemplateLibrary = (data) => ({ type: TemplatesListTypes.GET_TEMPLATE_LIBRARY, data });

export const deleteTemplateRequest = (id) => async (dispatch, getState) => {
  const listTemplate = _get(getState(), 'templateList.templates', []);
  const newListTemplate = listTemplate.filter((template) => template._id !== id);
  try {
    dispatch({ type: TemplatesListTypes.DELETE_TEMPLATE_REQUEST, newListTemplate });
    await axiosClient.delete(ENDPOINTS.deleteTemplate(id));
    dispatch(deleteTemplateSuccess());
  } catch (e) {
    console.log(e);
    dispatch(deleteTemplateFailure(e, listTemplate));
  }
};

export const deleteTemplateSuccess = (templates) => ({
  type: TemplatesListTypes.DELETE_TEMPLATE_SUCCESS,
  templates
});
export const deleteTemplateFailure = (error, listTemplate) => ({
  type: TemplatesListTypes.DELETE_TEMPLATE_FAILURE,
  error,
  listTemplate
});
